import React from 'react'
import { Link } from 'gatsby'
// import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css'
import './allprojects.scss'
import loadable from '@loadable/component'
const Carousel = loadable(() => import('react-multi-carousel'))

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 993 },
    items: 2
  },
  tablet: {
    breakpoint: { max: 992, min: 769 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1
  }
}

class AllProjects extends React.Component {
  state = {
    additionalTransfrom: 0,
    activeButton: '',
    show: false,
    goingUp: false
  }
  toggleCarousel = buttonName => {
    this.setState({
      activeButton: buttonName
    })
  }
  render() {
    const desktopViewProjectsList = this.props.edges
    // var mobileViewProjectList = this.props.edges.slice(0, length).map(i => {
    //     return i;
    // })
    const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
      const {
        carouselState: { currentSlide }
      } = rest
      return (
        <div className="carousel-control-container text-right">
          <div className="carousel-btn-group d-flex align-items-center justify-content-end">
            <button
              className={
                // currentSlide === 0 && this.state.activeButton != 'prev' ? 'disable' : 'active'
                this.state.activeButton === 'prev' ? 'active' : ''
              }
              onClick={() => {
                this.toggleCarousel('prev')
                previous()
              }}
            >
              <svg
                className="d-block"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle className="btn-bg" cx="20" cy="20" r="20" />
                <path
                  className="btn-arrow"
                  d="M23.5 10L13 20.5L23.5 31"
                  stroke-width="4"
                />
              </svg>
            </button>
            <button
              className={
                // currentSlide === 0 && this.state.activeButton != 'prev' ? 'disable' : 'active'
                this.state.activeButton === 'next' ? 'active' : ''
              }
              onClick={() => {
                this.toggleCarousel('next')
                goToSlide(currentSlide + 1)
              }}
            >
              <svg
                className="d-block"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  className="btn-bg"
                  cx="20"
                  cy="20"
                  r="20"
                  transform="rotate(-180 20 20)"
                />
                <path
                  className="btn-arrow"
                  d="M16.5 30L27 19.5L16.5 9"
                  stroke-width="4"
                />
              </svg>
            </button>
          </div>
          {/* <div
                onClick={() => {
                  navigate('/projects')
                }}
                aria-hidden="true"
                className="see-all-project"
              >
                See all of our projects
              </div> */}
        </div>
      )
    }
    return (
      <div className="project-carousel">
        {/* <div className="title-container"> */}
        {/* <p className="vertical-text">
              See All <span className="d-block d-lg-inline-block">of our</span>
            </p> */}
        {/* <h3>Projects</h3> */}
        {/* </div> */}
        <Carousel
          ssr={false}
          ref={el => (this.Carousel = el)}
          infinite={true}
          partialVisbile={false}
          responsive={responsive}
          showDots={false}
          containerClass="carousel-container-with-scrollbar"
          // additionalTransfrom={-this.state.additionalTransfrom}
          dotListClass="custom-dot-list-style "
          removeArrowOnDeviceType={['tablet', 'mobile']}
          itemClass="card-item"
          arrows={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup />}
          beforeChange={nextSlide => {
            if (nextSlide !== 0 && this.state.additionalTransfrom !== 150) {
              this.setState({ additionalTransfrom: 150 })
            }
            if (nextSlide === 0 && this.state.additionalTransfrom === 150) {
              this.setState({ additionalTransfrom: 0 })
            }
          }}
        >
          {desktopViewProjectsList.map(({ node }) => (
            <Link
              key={node.fields.slug}
              className="img-btn"
              to={node.fields.slug}
            >
              <img
                className="carousel-img"
                draggable={false}
                loading="lazy"
                src={node.frontmatter.homePageProjectImage}
                alt={node.frontmatter.title}
              />
              <p className="img-title">{node.frontmatter.title}</p>
              {/* <Fade timeout={500}>
                          <p className="img-title">{node.frontmatter.title}</p>
                        </Fade> */}
              {/* <Fade timeout={1000}>
                          <p className="img-dec">{node.frontmatter.description}</p>
                        </Fade> */}
            </Link>
          ))}
        </Carousel>
        {/* <div className="d-lg-none mobile-view">
            <div className="project-card">
              {mobileViewProjectList.map(({ node }) => (
                <div key={node.fields.slug} className="carousel-img-container">
                  <img
                    draggable={false}
                    loading="lazy"
                    className="carousel-img"
                    src={node.frontmatter.homePageProjectImage}
                    alt={node.frontmatter.title}
                  />
                  <p className="img-title">{node.frontmatter.title}</p>
                  <p className="img-dec">{node.frontmatter.description}</p>
                  <Link className="img-btn" to={node.fields.slug}>
                    Learn More
                  </Link>
                </div>
              ))}
            </div>
          </div> */}
        {/* <div className="see-all-project d-lg-none">See all of our projects</div> */}
      </div>
    )
  }
}

export default AllProjects
